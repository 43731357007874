import * as actionTypes from './actionTypes';
import { PaymentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export type PaymentStateType = {
  paymentList: ListResults<Payment> | null;
  paymentListLoading: boolean;
  paymentListError: HttpError;
  paymentCreateLoading: boolean;
  paymentCreateError: HttpError;
  redirectUrl: string | null;
  paymentCreateSuccess: boolean;
  couponPaymentCreateError: HttpError;
  couponPaymentCreateLoading: boolean;
  couponPaymentRedirectUrl: string | null;
  couponPaymentCreateSuccess: boolean;
  userPaymentsList: ListResults<Payment> | null;
  userPaymentsListLoading: boolean;
  userPaymentsListError: HttpError;
  userSubscriptionPaymentsList: ListResults<Payment> | null;
  userSubscriptionPaymentsListLoading: boolean;
  userSubscriptionPaymentsListError: HttpError;
  downloadMultiplePaymentInvoicesSuccess: boolean;
  downloadMultiplePaymentInvoicesLoading: boolean;
  downloadMultiplePaymentInvoicesError: HttpError;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentList: null,
  paymentListLoading: false,
  paymentListError: null,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: null,
  paymentCreateSuccess: false,
  couponPaymentCreateError: null,
  couponPaymentCreateLoading: false,
  couponPaymentRedirectUrl: null,
  couponPaymentCreateSuccess: false,
  userPaymentsList: null,
  userPaymentsListLoading: false,
  userPaymentsListError: null,
  userSubscriptionPaymentsList: null,
  userSubscriptionPaymentsListLoading: false,
  userSubscriptionPaymentsListError: null,
  downloadMultiplePaymentInvoicesSuccess: false,
  downloadMultiplePaymentInvoicesLoading: false,
  downloadMultiplePaymentInvoicesError: null,
};

const fetchPaymentListStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentListLoading: true,
});

const fetchPaymentListSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentList: action.paymentList,
  paymentListLoading: false,
});

const fetchPaymentListFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentListError: action.paymentListError,
  paymentListLoading: false,
});

const createPaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCreateLoading: true,
  paymentCreateSuccess: false,
});

const createPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: action.redirectUrl,
  paymentCreateSuccess: true,
});

const createPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: action.paymentCreateError,
  paymentCreateSuccess: false,
});

const createCouponPaymentStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  couponPaymentCreateLoading: true,
  couponPaymentCreateSuccess: false,
});

const createCouponPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  couponPaymentCreateLoading: false,
  couponPaymentCreateError: null,
  couponPaymentRedirectUrl: action.couponPaymentRedirectUrl,
  couponPaymentCreateSuccess: true,
});

const createCouponPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  couponPaymentCreateLoading: false,
  couponPaymentCreateError: action.couponPaymentCreateError,
  couponPaymentCreateSuccess: false,
});

const fetchUserPaymentsListStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  userPaymentsListLoading: true,
});

const fetchUserPaymentsListSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  userPaymentsList: action.userPaymentsList,
  userPaymentsListLoading: false,
  userPaymentsListError: null,
});

const fetchUserPaymentsListFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  userPaymentsListError: action.userPaymentsListError,
  userPaymentsListLoading: false,
});

const fetchUserSubscriptionPaymentsListStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  userSubscriptionPaymentsListLoading: true,
});

const fetchUserSubscriptionPaymentsListSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  userSubscriptionPaymentsList: action.userSubscriptionPaymentsList,
  userSubscriptionPaymentsListLoading: false,
  userSubscriptionPaymentsListError: null,
});

const fetchUserSubscriptionPaymentsListFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  userSubscriptionPaymentsListError: action.userSubscriptionPaymentsListError,
  userSubscriptionPaymentsListLoading: false,
});

const downloadMultiplePaymentInvoicesStart = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadMultiplePaymentInvoicesSuccess: false,
  downloadMultiplePaymentInvoicesLoading: true,
});

const downloadMultiplePaymentInvoicesSuccess = (
  state: PaymentStateType,
): PaymentStateType => ({
  ...state,
  downloadMultiplePaymentInvoicesSuccess: true,
  downloadMultiplePaymentInvoicesLoading: false,
  downloadMultiplePaymentInvoicesError: null,
});

const downloadMultiplePaymentInvoicesFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  downloadMultiplePaymentInvoicesSuccess: false,
  downloadMultiplePaymentInvoicesError:
    action.downloadMultiplePaymentInvoicesError,
  downloadMultiplePaymentInvoicesLoading: false,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const logout = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_LIST_START:
      return fetchPaymentListStart(state);
    case actionTypes.FETCH_PAYMENT_LIST_SUCCESS:
      return fetchPaymentListSuccess(state, action);
    case actionTypes.FETCH_PAYMENT_LIST_FAIL:
      return fetchPaymentListFail(state, action);
    case actionTypes.CREATE_PAYMENT_START:
      return createPaymentStart(state);
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return createPaymentSuccess(state, action);
    case actionTypes.CREATE_PAYMENT_FAIL:
      return createPaymentFail(state, action);
    case actionTypes.CREATE_COUPON_PAYMENT_START:
      return createCouponPaymentStart(state);
    case actionTypes.CREATE_COUPON_PAYMENT_SUCCESS:
      return createCouponPaymentSuccess(state, action);
    case actionTypes.CREATE_COUPON_PAYMENT_FAIL:
      return createCouponPaymentFail(state, action);
    case actionTypes.FETCH_USER_PAYMENTS_LIST_START:
      return fetchUserPaymentsListStart(state);
    case actionTypes.FETCH_USER_PAYMENTS_LIST_SUCCESS:
      return fetchUserPaymentsListSuccess(state, action);
    case actionTypes.FETCH_USER_PAYMENTS_LIST_FAIL:
      return fetchUserPaymentsListFail(state, action);
    case actionTypes.FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_START:
      return fetchUserSubscriptionPaymentsListStart(state);
    case actionTypes.FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_SUCCESS:
      return fetchUserSubscriptionPaymentsListSuccess(state, action);
    case actionTypes.FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_FAIL:
      return fetchUserSubscriptionPaymentsListFail(state, action);
    case actionTypes.DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_START:
      return downloadMultiplePaymentInvoicesStart(state);
    case actionTypes.DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_SUCCESS:
      return downloadMultiplePaymentInvoicesSuccess(state);
    case actionTypes.DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_FAIL:
      return downloadMultiplePaymentInvoicesFail(state, action);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
