import * as actionTypes from './actionTypes';
import { ReservationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Reservation } from '../../domain/Reservation';

export type ReservationStateType = {
  sessionReservationsList: ListResults<Reservation> | null;
  sessionReservationsListLoading: boolean;
  sessionReservationsListError: HttpError;
  sessionReservationsListUpdateNeeded: boolean;
  userReservationsList: ListResults<Reservation> | null;
  userReservationsListLoading: boolean;
  userReservationsListError: HttpError;
  createdReservation: Reservation | null;
  createReservationLoading: boolean;
  createReservationError: HttpError;
  reservationDeleteSuccess: boolean;
  reservationDeleteLoading: boolean;
  reservationDeleteError: HttpError;
  userReservationCancelSuccess: boolean;
  userReservationCancelLoading: boolean;
  userReservationCancelError: HttpError;
};

export type ReservationActionType = ReservationStateType & {
  type: ReservationActionTypes;
};

export const initialState: ReservationStateType = {
  sessionReservationsList: null,
  sessionReservationsListLoading: false,
  sessionReservationsListError: null,
  sessionReservationsListUpdateNeeded: false,
  userReservationsList: null,
  userReservationsListLoading: false,
  userReservationsListError: null,
  createdReservation: null,
  createReservationLoading: false,
  createReservationError: null,
  reservationDeleteSuccess: false,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  userReservationCancelSuccess: false,
  userReservationCancelLoading: false,
  userReservationCancelError: null,
};

const fetchSessionReservationsListStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  sessionReservationsListLoading: true,
});

const fetchSessionReservationsListSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  sessionReservationsList: action.sessionReservationsList,
  sessionReservationsListLoading: false,
  sessionReservationsListUpdateNeeded: false,
});

const fetchSessionReservationsListFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  sessionReservationsListError: action.sessionReservationsListError,
  sessionReservationsListLoading: false,
});

const fetchUserReservationsListStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationsListLoading: true,
  userReservationCancelSuccess: false,
});

const fetchUserReservationsListSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsList: action.userReservationsList,
  userReservationsListLoading: false,
  sessionReservationsListUpdateNeeded: false,
});

const fetchUserReservationsListFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationsListError: action.userReservationsListError,
  userReservationsListLoading: false,
});

const createReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  createReservationLoading: true,
  createReservationError: null,
});

const createReservationSuccess = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  createdReservation: action.createdReservation,
  createReservationLoading: false,
  createReservationError: null,
  sessionReservationsListUpdateNeeded: true,
});

const createReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  createdReservation: null,
  createReservationLoading: false,
  createReservationError: action.createReservationError,
});

const resetCreateReservationStore = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  createdReservation: null,
  createReservationLoading: false,
  createReservationError: null,
});

const deleteReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: true,
});

const deleteReservationSuccess = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: false,
  reservationDeleteError: null,
  reservationDeleteSuccess: true,
  sessionReservationsListUpdateNeeded: true,
});

const deleteReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  reservationDeleteLoading: false,
  reservationDeleteError: action.reservationDeleteError,
});

const cancelUserReservationStart = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationCancelLoading: true,
});

const cancelUserReservationSuccess = (
  state: ReservationStateType,
): ReservationStateType => ({
  ...state,
  userReservationCancelLoading: false,
  userReservationCancelError: null,
  userReservationCancelSuccess: true,
});

const cancelUserReservationFail = (
  state: ReservationStateType,
  action: ReservationActionType,
): ReservationStateType => ({
  ...state,
  userReservationCancelLoading: false,
  userReservationCancelError: action.reservationDeleteError,
});

const resetReservationStore = (): ReservationStateType => ({
  ...initialState,
});

const logout = (): ReservationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ReservationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SESSION_RESERVATIONS_LIST_START:
      return fetchSessionReservationsListStart(state);
    case actionTypes.FETCH_SESSION_RESERVATIONS_LIST_SUCCESS:
      return fetchSessionReservationsListSuccess(state, action);
    case actionTypes.FETCH_SESSION_RESERVATIONS_LIST_FAIL:
      return fetchSessionReservationsListFail(state, action);
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_START:
      return fetchUserReservationsListStart(state);
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_SUCCESS:
      return fetchUserReservationsListSuccess(state, action);
    case actionTypes.FETCH_USER_RESERVATIONS_LIST_FAIL:
      return fetchUserReservationsListFail(state, action);
    case actionTypes.CREATE_RESERVATION_START:
      return createReservationStart(state);
    case actionTypes.CREATE_RESERVATION_SUCCESS:
      return createReservationSuccess(state, action);
    case actionTypes.CREATE_RESERVATION_FAIL:
      return createReservationFail(state, action);
    case actionTypes.RESET_RESERVATION_STORE:
      return resetCreateReservationStore(state);
    case actionTypes.DELETE_RESERVATION_START:
      return deleteReservationStart(state);
    case actionTypes.DELETE_RESERVATION_FAIL:
      return deleteReservationFail(state, action);
    case actionTypes.DELETE_RESERVATION_SUCCESS:
      return deleteReservationSuccess(state);
    case actionTypes.CANCEL_USER_RESERVATION_START:
      return cancelUserReservationStart(state);
    case actionTypes.CANCEL_USER_RESERVATION_SUCCESS:
      return cancelUserReservationSuccess(state);
    case actionTypes.CANCEL_USER_RESERVATION_FAIL:
      return cancelUserReservationFail(state, action);
    case actionTypes.RESET_RESERVATION_STORE:
      return resetReservationStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
