export const FETCH_STATISTICS_START = 'FETCH_STATISTICS_START';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const FETCH_STATISTICS_FAIL = 'FETCH_STATISTICS_SUCCESS';

export const RESET_STATISTICS_STORE = 'RESET_STATISTICS_STORE';

export const LOGOUT = 'LOGOUT';

export type StatisticsActionTypes =
  | typeof FETCH_STATISTICS_START
  | typeof FETCH_STATISTICS_SUCCESS
  | typeof FETCH_STATISTICS_FAIL
  | typeof RESET_STATISTICS_STORE
  | typeof LOGOUT;
